.share-image-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.70);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.share-image-viewer {
  position: relative;
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.70);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.share-image-viewer * {
  box-sizing: border-box;
}

.share-image-viewer__content {
  position: fixed;
  width: 380px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: auto;
  overflow: auto;
  max-height: 100vh;
}

.share-image-viewer__footer {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(45, 45, 45, 0.8);
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 24px;
  gap: 16px;
  padding: 6px 8px;
  border-radius: 8px;
}

.share-image-viewer__footer  .deeplang-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}