.related-charts-tour-container div.ant-tour-description {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.related-charts-tour-container div.ant-tour-inner {

  background-color: rgb(66, 102, 217);
}

.related-charts-tour-container .ant-tour-close svg path {
  fill: #ffffff;
}

.related-charts-tour-container div.ant-tour-footer {
  visibility: hidden;
}