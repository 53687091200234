.panel {
  position: relative;
  width: 247px;
  border-radius: 8px;
  color: #2D2D2D;
  background-color: white;
}

.header {
  padding: 16px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.titleText {
  color: var(--Text-Title, #1A1A1A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.clear {
  display: flex;
  width: 28px;
  height: 24px;
  color: var(--Character-The-third, #9E9E9E);
  /* Body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
}

.main {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.footer {
  display: flex;
  padding: 0px 16px 10px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}