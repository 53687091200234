.container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;

  width: 100%;
  height: 100%;
}

.noRenderDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 100%;

  color: #2D2D2D;
  text-align: center;
  /* H4/medium */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.refresh {
  color: #5B82E5;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* 157.143% */
  text-decoration-line: underline;

  cursor: pointer;
}