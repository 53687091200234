.modal {
  display: flex;
  width: 320px;
  flex-direction: column;
  border-radius: 4px;
  background: var(--background, #2d2d2d);
  color: var(--color, #fff);
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.header {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-size: 16px;
  margin: 0;
  color: inherit;
}

.close {
  cursor: pointer;
}

.body {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
}

.list {
  list-style: none;
  padding-left: 0;
}

.item {
  display: flex;
  align-items: center;
}

.item + .item {
  margin-top: 10px;
}

.circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.on {
  background-color: #2d2d2d;
  border-width: 4px;
}

.footer {
  display: flex;
  padding: 10px 16px;
  justify-content: flex-end;
}

.cancel,
.confirm {
  font-size: 14px;
  cursor: pointer;
}

.cancel {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: var(--color);
  color: var(--background);
}

.confirm {
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #5b82e5;
  color: var(--color);
  background: #5b82e5;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
}
