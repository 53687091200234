.note-input-modal, .note-input-card, .ant-modal-wrap {
  user-select: none;
}

.note-input-modal .ant-modal-content {
  padding: 0 0 20px 0 !important;
}

.note-input-card {

}
.note-input-card .deeplang-icon {
  user-select: none;
}