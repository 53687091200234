.list {
  padding-right: 10px;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 8px;
}

.disabled {
  color: #B7B8B9;
  cursor: not-allowed;
}

.item p {
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.create {
  cursor: pointer;
}