.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  gap: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.relatedChartsText {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}

.sourceChart {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  cursor: pointer;
}

.icon {
  display: flex;
  padding: 5px;
  border-radius: 6px;
  background: var(--Brand-B6, #464646);

  justify-content: center;
  align-items: center;
}

.divider {
  width: 1px;
  height: 20px;
  background: var(--Text-Third, #9E9E9E);
}

.sourceChartText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Text-Secondary, #7A7B7B);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.sourceChartText:hover {
  color: var(---B1, #3667E1);
}

.searchBtnContainer {
  height: 24px;
}