.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 12px;
}

.content {
  width: 100%;
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;

  gap: 12px;

  flex-direction: column;
}

.description {

  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 4px;

  color: var(--Character-Primary, #2D2D2D);
  text-align: center;
  /* H4/regular */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}