.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 8px 8px 12px 8px;
  gap: 8px;
  border-radius: 12px;
  background-color: #fff;
  max-height: 312px;
}

.header {
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;
  width: 100%;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}


.main {
  display: flex;
  height: 192px;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  justify-content: center;
}

.main img {
  max-height: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.materialNameText {
  padding: 0px 4px;
  text-align: left;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Text-Primary, #2D2D2D);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.materialNameText:hover {
  color: var(---B1, #3667E1);
}

.secondaryInfo {
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.chartSourceText,
.organizationText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--Text-Third, #9E9E9E);
  text-overflow: ellipsis;
  /* Footnote/description */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}