.toolbar {
  display: none;
  position: absolute;
  height: 30px;
  flex-direction: column;
  user-select: none;
  z-index: 9999;
}


.toolbar:hover .close {
  display: block;
}

.tool-bar {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px;
  gap: 6px;

  border-radius: 8px;
  /* drop-shadow/0.12+0.8+0.5 */
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.tool-bar :global(.ant-dropdown-menu) {
  background: var(--background, white);
  min-width: 74px;
}

.tool-bar :global(.ant-dropdown-menu-item) {
  color: #fff !important;
  padding: 5px 8px !important;
}

.close {
  display: none;
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
  line-height: 0;
  z-index: 1;
}

.color-item {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 8px;
  color: #2D2D2D;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 0px 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #1A1A1A;
  gap: 6px;
  cursor: pointer;
}

.item:nth-last-child(1) {
  border-right: none;
}

/* .item svg+span {
  margin-left: 6px;
} */

.pencil {
  position: relative;
}

.pencil svg {
  position: relative;
  z-index: 100;
}

.pencil-color {
  position: absolute;
  left: 16px;
  bottom: 12px;
  width: 22px;
  height: 6px;
  background: yellow;
  z-index: 0;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

