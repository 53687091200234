.container {
  width: 100%;
  gap: 24px;
  padding-bottom: 40px;
  height: calc(-162px + 100vh);
  display: flex;
  justify-content: center;
  padding: 0px 32px;
  align-items: center;
}

.imageContainer {
  width: 100%;
}