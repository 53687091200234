.container {
  height: 100%;
  width: 100%;

  position: relative;
}

.main {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  height: 100%;
  width: 100%;
  background: var(--Primary-BG, #FBFBFB);
  position: relative;
}

.pdfViewContainer {
  width: 100%;
  position: absolute;
  inset: 0px 0 0;
  outline: none;
  overflow: auto;
  box-sizing: content-box;
  background-color: #FBFBFB;
  /* margin-top: 60px; */
}

.pdfViewContainer * {
  box-sizing: content-box;
}