.tag {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #f7f7f7;
  color: var(--background, #2d2d2d);
  margin-bottom: 4px;
}

.name {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close {
  display: flex;
  align-items: center;
  margin-left: 3px;
  cursor: pointer;
}
