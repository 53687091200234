.container1Col,
.container3Col,
.containerGrid {
  overflow: auto;
}

.containerGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;
}

.container1Col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  width: 100%;
}

.containerGrid::-webkit-scrollbar,
.container3Col::-webkit-scrollbar,
.container1Col::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}

.container3Col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 100%;
}