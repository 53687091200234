.wrap {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
  min-height: 32px;
  width: 100%;
}

.tag {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;
  max-width: 100%;
}

.box {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: 2px 4px;
  overflow: hidden;
  color: #fff;
}

.mirror {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: auto;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
  min-width: 5px;
}

.inputbox {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  max-width: 100%;
}

.input {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  z-index: 2;
}

.placeholder {
  position: absolute;
  margin: 0;
  margin-left: 7px;
  color: #b7b8b9;
}

.input input {
  width: 100%;
  height: 20px;
  min-width: 4px;
  outline: none;
  border: none;
  color: var(--background, #2d2d2d);
  background-color: transparent;
}